import React, { useMemo, useState } from 'react';
import Link from 'next/link';
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faCartShopping, faClose, faSearch, faSign, faSignOut, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AppModal from '@/lib/components/modal';
import LoginPage from '@/lib/components/auth/users/login-page';
import useAuth from '@/lib/components/auth/hooks/useAuth';


type NavToolItemProps = {
    key: string;
    title: string;
    path?: string;
    children?: React.ReactNode;
    Icon?: any
};

interface NavToolsProps {
    items?: NavToolItemProps[];
}


const NavTools: React.FC<NavToolsProps> = (props) => {

    const { isAuthenticated } = useAuth();

    const items = useMemo(() => [
        {
            key: "login",
            title: "Login",
            Icon: isAuthenticated ? faSignOut : faUser,
            path: isAuthenticated ? '/auth/logout' : '/login',
            children: !isAuthenticated ? <LoginPage /> : null
        }, {
            key: "cart",
            title: "Cart",
            Icon: faCartShopping,
            path: '/cart'
        }, {
            key: "search",
            title: "Search",
            Icon: faSearch,
            path: '/shop'
        },
    ], [isAuthenticated])

    return (
        <nav>
            <ul className='flex flex-row items-center justify-center gap-2'>
                {items.map((item, key) => <NavTool key={key} item={item} />)}
            </ul>
        </nav>
    );
};

const NavTool = ({ item }: { item: NavToolItemProps }) => {
    const [isVisible, setIsVisible] = useState(false);
    const open = () => setIsVisible(true);
    const close = () => setIsVisible(false);
    return (
        <li>
            {item.children ?
                <button type='button' onClick={open} className='w-[50px] h-[50px] flex flex-row items-center justify-center'>{item.Icon ? <Icon size='lg' icon={item.Icon} /> : <span>{item.title}</span>}</button> :
                <Link href={item.path ?? "#"} className='w-[50px] h-[50px] flex flex-row items-center justify-center'>{item.Icon ? <Icon size='lg' icon={item.Icon} /> : <span>{item.title}</span>}</Link>
            }
            {(item.children && isVisible) &&
                <AppModal className='' IconClose={<Icon icon={faClose} size='xl' />} visible={isVisible} show={open} close={close}>{item.children}</AppModal>
            }
        </li>
    )
}

export default NavTools;
