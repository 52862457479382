import React from 'react';

type NavLineProps = {
    name: string;
};

const NavLine: React.FC<NavLineProps> = ({ name }) => {
    return (
        <div>
            <p>{name}</p>
            <div></div>
        </div>
    );
};

export default NavLine;
