"use client"

import Link from 'next/link';
import React, { ReactNode } from 'react';
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

interface Props {
    socials: {
        Icon: any;
        url: string;
        title?: string;
    }[]
}

const Socials: React.FC<Props> = ({ socials }) => {
    return (
        <div className='flex flex-row gap-10 py-10'>
            {socials.map((social, key) => (
                <div key={key} className='text-gray'>
                    <Link href={social.url} target="_blank" rel="noreferrer"><Icon icon={social.Icon} size='2xl' /></Link>
                </div>
            ))}
        </div>
    );
};

export default Socials;
