import React, { createContext, useState } from 'react';

interface AuthContextProps {
    user: any;
    isAuthenticated: boolean;
    login: (creds: { email: string, password: string }) => Promise<any>;
    logout: () => Promise<void>;
}

export const authContext = createContext<AuthContextProps>({
    user: null,
    isAuthenticated: false,
    login: async (creds: { email: string, password: string }) => { },
    logout: async () => { },
});
