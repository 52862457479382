import React, { FC } from 'react';
import Link from 'next/link';
import config from '@/config';
import Image from 'next/image';
import classNames from 'classnames';

type BrandLogoProps = {
    src?: string
    className?: string
};

const BrandLogo: FC<BrandLogoProps> = ({ src = config.site.logo, className }) => {
    return (
        <Link href="/" className={classNames(className, 'flex items-center justify-center')}>
            <div className="py-2 md:py-5 max-w-[150px] md:max-w-[250px]">
                <Image width={1080} height={768} className="w-full" src={src} alt="Brand Logo" priority />
            </div>
        </Link>
    );
};

export default BrandLogo;
