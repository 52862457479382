"use client"
import MainNav from '@/lib/components/main-nav';
import React from 'react';
import NavLine from '../nav-line';
import NavTools from '../nav-tools';

interface AppMainNavProps {
    className?: string
}

const menuItems = ['Baby Shower', 'Bebe', 'Minis', "Teens"].map((item) => ({
    key: item.toLocaleLowerCase(),
    title: item,
    path: '/shop/' + item.toLowerCase(),
    children: <NavLine name={item} />
}))

const AppMainNav: React.FC<AppMainNavProps> = ({ className }) => {
    return (
        <>
            <MainNav items={menuItems} className={"md:flex hidden"}>
                <NavTools />
            </MainNav>
        </>
    );
};

export default AppMainNav;
