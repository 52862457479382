"use client"
import classNames from 'classnames';
import Link from 'next/link';
import React, { FC, ReactNode, useRef, useState } from 'react';

export type MenuItemProps = {
    key: string;
    title: string;
    path: string;
    children?: ReactNode;
}

const MainNav: FC<{ items: MenuItemProps[], children?: ReactNode, className?: string }> = ({ items, children, className }) => {
    return (
        <nav className={classNames("bg-transparent flex flex-row items-center justify-center gap-20", className)}>
            <ul className="flex flex-row gap-2 justify-center p-5">
                <div className='hover:bg-white hover:text-dark p-2 px-5'>
                    <Link href={"/"} className='uppercase text-nowrap font-bold text-lg text-inherit hover:underline'>
                        Inicio
                    </Link>
                </div>
                <MenuItems items={items} />
            </ul>
            {children && <ul>{children}</ul>}
        </nav>
    );
};

const MenuItem = ({ item }: { item: MenuItemProps }) => {
    const [isVisible, setIsVisible] = useState(false);

    const childRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => setIsVisible(true);

    const handleMouseLeave = () => setIsVisible(false);

    return (
        <div className='hover:bg-white hover:text-dark p-2 px-5'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <Link href={item.path} className='uppercase text-nowrap font-bold text-lg text-inherit hover:underline'>
                {item.title}
            </Link>
            {item.children &&
                <div ref={childRef} className={classNames("w-full left-0 absolute bg-white text-dark p-10 z-10", { 'hidden': !isVisible })}>
                    {item.children}
                </div>
            }
        </div>

    )
}

const MenuItems = ({ items }: { items: MenuItemProps[] }) => {
    return items.map((item, index) => (
        <li key={index} className="cursor-pointer">
            <MenuItem item={item}></MenuItem>
        </li>
    ));
}

export default MainNav;
