"use client"
import React, { ErrorInfo } from 'react';

interface ErrorBoundaryProps {
    children?: React.ReactNode;
    fallback?: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });
        // You can also log the error to an error reporting service here
        console.warn(error, errorInfo.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can customize the error UI here
            return (
                <div className='text-center h-full w-full flex flex-col justify-center items-center'>
                    <h1 className='font-bold text-5xl py-5'>Error</h1>
                    <p className='text-2xl py-5'>Oops! Algo salió mal.</p>
                    <div className='pt-20'><a href="/">Volver al inicio</a></div>
                    <p>{this.state.error?.toString()}</p>
                    {/* <p>{this.state.errorInfo?.componentStack}</p> */}
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
