import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const config = {
    site: {
        title: 'Crayon',
        description: 'Crayon ropas de chicos',
        footer: "Copyright. Todos los derechos reservados",
        logo: "https://s3.us-east-1.amazonaws.com/media.crayonropadechicos.com.ar/uploads/assets/img/logo-crayon.png"
    },
    org: {
        emails: [
            "ventas@crayonropadechicos.com.ar",
            "info@crayonropadechicos.com.ar"
        ],
        phones: [
            "03543-446799"
        ],
        address: {
            street: "Av. San Martín 123",
            city: "Villa Carlos Paz",
            province: "Córdoba",
            country: "Argentina"
        },
        socials: [
            { "title": "instagram", "url": "https://www.instagram.com/crayonropadechicos", "Icon": faInstagram },
            { "title": "facebook", "url": "https://www.facebook.com/crayonropadechicos", "Icon": faFacebook },
            { "title": "whatsapp", "url": "https://wa.me/03543446799", "Icon": faWhatsapp }
        ],
        datafiscal: {
            link: "http://qr.afip.gob.ar/?qr=7fvzNMzJvOfE3U-aiAYTVA,,",
            image: "/assets/images/crayon-datafiscal.jpg",
        },
        stackLinks: [
            {
                title: "Contacto",
                children: [
                    { title: "ventas@crayonropadechicos.com.ar", url: "mailto:ventas@crayonropadechicos.com.ar" },
                    { title: "info@crayonropadechicos.com.ar ", url: "mailto:info@crayonropadechicos.com.ar " },
                    { title: "03543-446799", url: "tel:03543446799" }
                ]
            },
            {
                title: "Información",
                children: [
                    { title: "Locales", url: "#" },
                    { title: "Sobre Nosotros", url: "#" },
                    { title: "Compras Mayoristas", url: "#" },
                    { title: "Envíos y retiros en tienda", url: "#" },
                    { title: "Trabaja con nosotros", url: "#" }
                ]
            },
            {
                title: "Ayuda",
                children: [
                    { title: "Contactanos", url: "#" },
                    { title: "Preguntas frecuentes", url: "#" },
                    { title: "Cambios y devoluciones", url: "#" },
                    { title: "Términos y condiciones", url: "#" },
                ]
            },
            {
                title: "Mi Cuenta",
                children: [
                    { title: "Mis datos", url: "#" },
                    { title: "Historial de compras", url: "#" },
                    { title: "Seguimiento de compras", url: "#" }
                ]
            }
        ]
    },
    google: {
        clientId: 'your-google-client-id',
        clientSecret: 'your-google'
    },
    apis: {
        tienda: process.env.NEXT_PUBLIC_TIENDA_API_URL ?? "/api",
    }
}
export default config
