"use client"

import BrandLogo from '@/lib/components/brand-logo';
import React, { useState } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import NavTools from '../nav-tools';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';

interface MobileNavProps {
    // Define props here
}

const MobileNav: React.FC<MobileNavProps> = () => {
    const [isVisible, setIsVisible] = useState(false);
    const openMenu = () => setIsVisible(true);
    const closeMenu = () => setIsVisible(false);
    return (
        <>
            <nav className='md:hidden flex items-center justify-between'>
                <button type='button' onClick={openMenu} className='w-[50px] h-[50px] flex items-center justify-center'>
                    <Icon icon={faBars} size='xl' />
                </button>
                <BrandLogo />
                <NavTools />
            </nav>
            {isVisible &&
                <div className='bg-white text-dark absolute top-0 left-0 z-10 h-full flex flex-col'>
                    <div className='flex flex-row items-center justify-between'>
                        <BrandLogo />
                        <button type='button' onClick={closeMenu} className='w-[50px] h-[50px] flex items-center justify-center'>
                            <Icon icon={faClose} size='xl' />
                        </button>
                    </div>
                    <div className='flex-1 overflow-auto'></div>
                </div>
            }
        </>
    );
};

export default MobileNav;
