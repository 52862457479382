"use client"
import classNames from "classnames";
import { FC, ReactNode, useState } from "react";



const AppHeader: FC<{ isHome?: boolean; banners?: string[]; children: ReactNode }> = ({ isHome, children, banners }) => {
    const [bannerImg, setBannerImg] = useState(banners?.at(0))

    return (
        // @ts-ignore
        <header style={{ '--image-url': `url(${bannerImg})` }} className={classNames({ "h-[80vh] bg-cover bg-left-top bg-[image:var(--image-url)]": isHome }, "p-4 bg-gray text-white")}>
            {children}
        </header>
    )
}

export default AppHeader;
