import React from 'react';
import Link from 'next/link';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import useAuth from '../hooks/useAuth';


const LoginPage: React.FC<{}> = () => {

    const auth = useAuth();

    const initialValues = {
        email: '',
        password: ''
    };

    const handleSubmit = async (values: any) => {
        await auth.login(values)
    };

    const validateForm = (values: any) => {
        const errors: any = {};

        if (!values.email) {
            errors.email = 'Email is required';
        }

        if (!values.password) {
            errors.password = 'Password is required';
        }

        return errors;
    };

    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row items-center justify-center py-5'>
                <h1 className='font-bold text-3xl'>Inicio de sesión</h1>
            </div>
            <div className='py-5'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                >
                    <Form>
                        <div className='flex flex-col mb-5'>
                            <Field type="email" id="email" name="email" placeholder="email@mail.com" className="px-5 py-2 border-2 border-gray rounded-lg" />
                            <ErrorMessage name="email" component="div" className='text-xs' />
                        </div>
                        <div className='flex flex-col mb-5'>
                            <Field type="password" id="password" name="password" placeholder="****" className="px-5 py-2 border-2 border-gray rounded-lg" />
                            <ErrorMessage name="password" component="div" className='text-xs' />
                        </div>
                        <div className='flex flex-col items-center gap-5'>
                            <div>
                                <button type="submit" className='px-10 py-2 border bg-primary text-white rounded-full'>Ingresar</button>
                            </div>
                            <div>
                                <Link href={"/users/register"}>Olvidé mi contraseña</Link>
                            </div>
                            <div className='flex flex-row gap-5'>
                                <div>¿No tienes cuenta?</div>
                                <Link href={"/users/register"}>Regístrate</Link>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};


export default LoginPage;

